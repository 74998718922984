document.addEventListener("DOMContentLoaded", () => {
  const modal = document.querySelector(".js-location-modal");
  const allowButton = document.querySelector(".js-allow-btn");
  const denyButton = document.querySelector(".js-deny-btn");
  const closeButton = document.querySelector(".js-modal-location-close");
  const modalContent = document.querySelector(".modal-location__content");
  const ssSearch = document.querySelector(".js-ss-search");

  const bottomPcSearchBar = document.querySelector(".search-bar.-pc");
  const bottomSpSearchBar = document.querySelector(".search-bar.-sp.-sr");
  const bottomSpRecruitOnlySearchBar = document.querySelector(
    ".search-bar.-sp:has(.-recruit-only)"
  );

  // const spTwoButton = document.querySelector('.search-bar.-sp.-sr');
  // const spOneButton = document.querySelector('.search-bar.-sp.-dummy-bottom');
  const locationPermissionGranted = localStorage.getItem(
    "locationPermissionGranted"
  );
  const locationPermissionDeniedTimer = localStorage.getItem(
    "locationPermissionDeniedTimer"
  );
  const isLocationPermissionGranted =
    locationPermissionGranted === "true" ? true : false;

  bottomPcSearchBar.classList.add("-denied");
  bottomSpSearchBar.classList.add("-denied");
  bottomSpRecruitOnlySearchBar.classList.add("-denied");

  if (isLocationPermissionGranted) {
    getLocation();
    document.dispatchEvent(
      new CustomEvent("load-old-nearest-shop", {
        detail: {},
      })
    );
  } else {
    const time = locationPermissionDeniedTimer
      ? parseInt(locationPermissionDeniedTimer)
      : 0;
    const now = new Date().getTime();
    const diff = now - time;
    const isMoreThanThirtyMinutes = diff > 1000 * 60 * 30;
    if (isMoreThanThirtyMinutes) {
      modal.style.display = "flex";
    } else {
      bottomSpRecruitOnlySearchBar.classList.remove("-denied");
    }
  }

  // function updateSearchBar() {
  //     if (window.innerWidth <= 1020) {
  //         searchBar = document.querySelector('.search-bar.-sp');
  //         isSpWidth = true;
  //     } else {
  //         searchBar = document.querySelector('.js-search-bar');
  //         isSpWidth = false;
  //     }
  // }

  // updateSearchBar();
  // window.addEventListener('resize', updateSearchBar);

  // ページ内に-deniedが存在するかチェック

  // if (hasDeniedClass) {
  //     // 位置情報が許可されているか、または拒否されているか確認
  //     if (isLocationPermissionGranted) {
  //         getLocation();
  //     } else if (isLocationPermissionDenied) {
  //         // 位置情報の権限が拒否されている場合の処理
  //         if (isSpWidth) {
  //             spOneButton.classList.remove('-denied');
  //             spTwoButton.classList.add('-denied');
  //         }
  //     } else {
  //         // 許可・拒否されていない場合はモーダルを表示
  //         modal.style.display = 'flex';
  //     }
  // }

  // 許可ボタンがクリックされた場合
  allowButton.addEventListener("click", () => {
    getLocation();

    setTimeout(() => {
      closeModal(false);
    }, 500);
  });

  // 拒否ボタンがクリックされた場合
  denyButton.addEventListener("click", () => {
    localStorage.setItem("locationPermissionGranted", "false");
    localStorage.setItem(
      "locationPermissionDeniedTimer",
      new Date().getTime().toString()
    );
    closeModal();
  });

  closeButton.addEventListener("click", () => {
    closeModal();
  });

  modal.addEventListener("click", (event) => {
    if (!modalContent.contains(event.target)) {
      closeModal();
    }
  });

  function closeModal(denied = true) {
    if (denied) {
      bottomSpRecruitOnlySearchBar.classList.remove("-denied");
    }
    modal.style.display = "none";
  }

  let isGettingLocation = false;
  function getLocation() {
    if (navigator.geolocation) {
      if (isGettingLocation) {
        return;
      }

      isGettingLocation = true;

      navigator.geolocation.getCurrentPosition(
        (position) => {
          document.dispatchEvent(
            new CustomEvent("can-access-location", {
              detail: {
                position: position,
              },
            })
          );
          localStorage.setItem("locationPermissionGranted", "true");
          isGettingLocation = false;
          closeModal(false);
        },
        (error) => {
          // localStorage.setItem('locationPermissionGranted', 'false');
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("位置情報の取得が拒否されました。");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("位置情報が利用できません。");
              break;
            case error.TIMEOUT:
              alert("位置情報の取得に時間がかかりすぎています。");
              break;
            default:
              alert("位置情報の取得に失敗しました。");
              break;
          }

          if (!isLocationPermissionGranted) {
            bottomSpRecruitOnlySearchBar.classList.remove("-denied");
          }

          isGettingLocation = false;
        },
        {
          timeout: 30 * 1000,
        }
      );
    } else {
      alert("このブラウザでは位置情報取得がサポートされていません。");
      closeModal();
    }
  }

  // js-ss-name要素の文字数チェックとクラスの追加
  const ssNameElements = document.querySelectorAll(".js-ss-name");
  ssNameElements.forEach((element) => {
    if (element.textContent.trim().length >= 17) {
      element.classList.add("-over-17");
    }
  });
});
